<!-- eslint-disable max-len -->
<template>
    <div class="incubator-data">
        <v-form
            ref="form"
            v-model="valid"
        >
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.organizeName"
                            outlined
                            :label="$t('IncubatorApply.TeamData.label[0]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.TeamData.hint[0]')"
                            persistent-hint
                            :rules="emptyRule"
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.team.type"
                        class="radio-group"
                        :rules="emptyRule"
                    >
                        <v-radio
                            value="非正式立案組織（個人團隊）"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.TeamData.radio[0]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="非營利組織"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.TeamData.radio[1]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="營利組織"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.TeamData.radio[2]') }}
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[2]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-select
                            v-model="form.team.country"
                            :items="countries"
                            outlined
                            :label="$t('IncubatorApply.TeamData.label[1]')"
                            :rules="emptyRule"
                            class="border-orange-input form-input"
                        />
                        <v-text-field
                            v-if="form.team.country !== '臺灣'"
                            v-model="form.team.city"
                            outlined
                            :label="$t('IncubatorApply.TeamData.label[2]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.TeamData.hint[1]')"
                            persistent-hint
                            :rules="emptyRule"
                            class="border-orange-input form-input"
                        />
                        <v-select
                            v-else
                            v-model="form.team.city"
                            :items="cities"
                            outlined
                            :label="$t('IncubatorApply.TeamData.label[3]')"
                            :rules="emptyRule"
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[3]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.taxId"
                            outlined
                            :label="$t('IncubatorApply.TeamData.label[4]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.TeamData.hint[2]')"
                            persistent-hint
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            :label="$t('IncubatorApply.TeamData.label[5]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.TeamData.hint[3]')"
                            persistent-hint
                            :rules="emptyRule"
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[5]') }}
                    </div>
                    <div class="tips">
                        {{ $t('IncubatorApply.TeamData.tips[0]') }}<br>{{ $t('IncubatorApply.TeamData.tips[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-action">
                        <div
                            class="delete-btn btn"
                            @click="deleteMember"
                        >
                            - {{ $t('IncubatorApply.TeamData.deleteMember') }}
                        </div>
                        <div
                            class="add-btn btn"
                            @click="addMember"
                        >
                            + {{ $t('IncubatorApply.TeamData.addMember') }}
                        </div>
                    </div>
                    <div class="member-list">
                        <div
                            v-for="(item, index) in form.team.members"
                            :key="index"
                            class="member-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="memberNameLabel(index)"
                                    placeholder=""
                                    :hint="$t('IncubatorApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    :label="$t('IncubatorApply.TeamData.label[8]')"
                                    placeholder=""
                                    :hint="$t('IncubatorApply.TeamData.hint[3]')"
                                    :rules="emptyRule"
                                    persistent-hint
                                    class="border-orange-input form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.department"
                                    outlined
                                    :label="$t('IncubatorApply.TeamData.label[9]')"
                                    placeholder=""
                                    :hint="$t('IncubatorApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <v-menu
                                    :ref="'memberMenu' + index"
                                    v-model="menu['memberMenu' + index]"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.birthday"
                                            outlined
                                            class="border-orange-input form-input"
                                            :label="$t('IncubatorApply.TeamData.label[10]')"
                                            :placeholder="$t('IncubatorApply.TeamData.label[10]')"
                                            :hint="$t('IncubatorApply.TeamData.hint[3]')"
                                            persistent-hint
                                            readonly
                                            v-bind="attrs"
                                            :rules="emptyRule"
                                            append-icon="mdi-calendar"
                                            v-on="on"
                                            @click:append="
                                                menu[
                                                    'memberMenu' + index
                                                ] = true
                                            "
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="item.birthday"
                                        no-title
                                        scrollable
                                        @input="
                                            menu['memberMenu' + index] = false
                                        "
                                    />
                                </v-menu>
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    :label="$t('IncubatorApply.TeamData.label[11]')"
                                    placeholder=""
                                    :hint="$t('IncubatorApply.TeamData.hint[3]')"
                                    :rules="emailRule"
                                    persistent-hint
                                    class="border-orange-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[6]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox-q[0]')"
                        style="pointer-events: none;"
                        class="checkbox checkbox-title"
                        :rules="refersRule"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][0]')"
                        value="FUN大視野官網"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][1]')"
                        value="FUN大視野FACEBOOK"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][2]')"
                        value="FUN大視野INSTAGRAM"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][3]')"
                        value="FUN大視野電子報"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][4]')"
                        value="學校活動公告(學校官網、海報、育成中心等)"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][5]')"
                        value="縣市政府宣傳平台"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][6]')"
                        value="社會創新平台官網"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][7]')"
                        value="獎金獵人平台"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][8]')"
                            value="歷屆團隊推薦，團隊名:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers1"
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][9]')"
                            value="親朋好友推薦，推薦人:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers2"
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][10]')"
                        value="曾經提案過"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][11]')"
                        value="原本就知道"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][12]')"
                        value="IMPACT HUB TAIPEI宣傳平台(官網、電子報、口頭邀約等)"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][13]')"
                        value="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][14]')"
                            value="媒體宣傳平台，平台名:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers3
                            "
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][15]')"
                            value="其他:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers4"
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block" />
                <div class="form-content">
                    <div class="actions">
                        <div
                            class="btn white-btn"
                            @click="resetForm"
                        >
                            {{ $t('IncubatorApply.TeamData.button[0]') }}
                        </div>
                        <div
                            class="btn blue-btn"
                            @click="save"
                        >
                            {{ $t('IncubatorApply.TeamData.button[1]') }}
                        </div>
                        <div
                            class="btn orange-btn"
                            @click="nextStep"
                        >
                            {{ $t('IncubatorApply.TeamData.button[2]') }}
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import {
    mapState, mapMutations, mapActions,
} from 'vuex';

export default {
    components: {},
    data() {
        return {
            menu: {},
            valid: false,
            loading: false,
            countries: ['臺灣', '其他'],
            cities: ['臺北市', '新北市', '基隆市', '桃園市', '新竹市', '新竹縣', '苗栗縣', '臺中市', '彰化縣', '南投縣', '雲林縣', '嘉義市', '嘉義縣', '臺南市', '高雄市', '屏東縣', '宜蘭縣', '花蓮縣', '臺東縣', '澎湖縣', '金門縣', '連江縣'],
            refers1: '',
            refers2: '',
            refers3: '',
            refers4: '',
            // rules
            emptyRule: [(v) => !!v || this.$t('IncubatorApply.TeamData.rule[0]')],
            emailRule: [
                (v) => !!v || this.$t('IncubatorApply.TeamData.rule[0]'),
                (v) => /.+@.+\..+/.test(v) || this.$t('IncubatorApply.TeamData.rule[1]'),
                (v) => !v.match(/\s/) || this.$t('IncubatorApply.TeamData.rule[2]'),
            ],
            refersRule: [
                (v) => v.length >= 1 || this.$t('IncubatorApply.TeamData.rule[0]'),
            ],
        };
    },
    computed: {
        ...mapState('mIncubatorApply', ['form', 'refersTeamName', 'refersRecommendName', 'refersPlatformName', 'refersOther']),
        applyId() {
            return this.$route.params.id;
        },
    },
    created() {
    },
    mounted() {
        if (this.applyId !== 'new') {
            this.loadApply(this.applyId);
        }
        setTimeout(() => {
            this.init();
        }, 2000);
    },
    methods: {
        ...mapMutations('mNotification', ['handleError', 'setAlert']),
        ...mapMutations('mIncubatorApply', ['updateRefersTeamName', 'updateRefersRecommendName', 'updateRefersPlatformName', 'updateRefersOther']),
        ...mapActions('mIncubatorApply', ['saveTeam', 'loadApply']),
        init() {
            this.refers1 = this.refersTeamName;
            this.refers2 = this.refersRecommendName;
            this.refers3 = this.refersPlatformName;
            this.refers4 = this.refersOther;
        },
        memberNameLabel(index) {
            if (index === 0) {
                return this.$t('IncubatorApply.TeamData.label[6]');
            }
            return `${this.$t('IncubatorApply.TeamData.label[7]')}${index}`;
        },
        deleteMember() {
            if (this.form.team.members.length > 1) {
                this.form.team.members.splice(-1);
            }
        },
        addMember() {
            if (this.form.team.members.length < 10) {
                this.form.team.members.push({
                    name: '',
                    phone: '',
                    email: '',
                    department: '',
                    birthday: '',
                });
            }
        },
        resetForm() {
            this.form.team = {
                organizeName: '',
                type: '',
                country: '',
                city: '',
                taxId: '',
                name: '',
                members: [
                    {
                        updateRefersname: '',
                        phone: '',
                        email: '',
                        department: '',
                        birthday: '',
                    },
                ],
            };
        },
        updateRefers() {
            this.updateRefersTeamName(this.refers1);
            this.updateRefersRecommendName(this.refers2);
            this.updateRefersPlatformName(this.refers3);
            this.updateRefersOther(this.refers4);
            // 修改報名來源資料
            const option1 = '歷屆團隊推薦，團隊名:';
            const option2 = '親朋好友推薦，推薦人:';
            const option3 = '媒體宣傳平台，平台名:';
            const option4 = '其他:';
            this.form.team.refers = this.form.team.refers.map((item) => {
                if (item === option1) {
                    return `${option1 + this.refersTeamName}`;
                } if (item === option2) {
                    return `${option2 + this.refersRecommendName}`;
                } if (item === option3) {
                    return `${option3 + this.refersPlatformName}`;
                } if (item === option4) {
                    return `${option4 + this.refersOther}`;
                }
                return item;
            });
        },
        async save() {
            this.updateRefers();
            await this.saveTeam({
                form: this.form.team,
                applyId: this.applyId,
            });
        },
        async nextStep() {
            this.updateRefers();
            this.$refs.form.validate();
            if (this.valid) {
                try {
                    this.$router.push({
                        name: 'IncubatorProposal',
                        params: {
                            id: this.applyId,
                        },
                    });
                    await this.saveTeam({
                        form: this.form.team,
                        applyId: this.applyId,
                    });
                } catch (error) {
                    this.setAlert({
                        message: this.$t('alertMessage.data-no-write'),
                        color: 'error',
                    });
                }
            } else {
                this.setAlert({
                    message: this.$t('alertMessage.data-no-write'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">
.incubator-data {
    .form-content {
        .teacher-item:not(:first-child) {
            border-top: 1px dashed $color-orange;
            padding-top: 25px;
        }

        .member-item:not(:first-child) {
            border-top: 1px dashed $color-orange;
            padding-top: 25px;
        }
    }

    .team-image-block {
        .preview-card {
            width: 300px;
            height: 300px;
        }

        .label {
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0.1em;
            color: $color-gray;
            margin-bottom: 23px;
        }

        .upload {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(196, 196, 196, 0.4);
            border: 1px solid #ffffff;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .v-input__prepend-outer {
                margin: 0 !important;
            }
        }
    }
}
.checkbox-title{
    margin-bottom: 15px;
}
.checkbox-title .v-label{
    color:#404040 !important;;
}
.checkbox-title .v-input--selection-controls__input{
    display: none;
}
.checkbox-input{
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
@media (min-width: 768px) {
    .checkbox-input{
        max-width: 185px !important;
    }
}
</style>
